import React, { FC } from 'react';
import { AccountContentContainer } from './style';
import Card from 'components/Card';

const PersonalDetailsContent: FC = () => {

  return (
    <AccountContentContainer>
      <Card
        padding='32px'
      >
        Personal Details
      </Card>
    </AccountContentContainer>
  );
};

export default PersonalDetailsContent;
