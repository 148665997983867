import styled from 'styled-components';
import { BasicSectionContainer } from '../style';

export const AccountContainer = styled(BasicSectionContainer)`
  min-height: 50vh;
  padding: 64px 0;
  display: flex;
  justify-content: center;

  .header {
    width: 100%;
    text-align: left;

    h1 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .account {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 32px;
    margin-top: 32px;
  }

`;
