import styled from 'styled-components';
import { colors } from 'constants/colors';

export const BasicSectionContainer = styled.section`
  width: 100%;
  box-sizing: border-box;

  .innerContainer {
    width: 100%;
    max-width: 1229px;
    box-sizing: border-box;
    padding: 0 31px;

    @media only screen and (max-width: 767px) {
      padding: 0 13px;
      min-width: 320px;
    }
  }
  .commonTitle {
    width: 100%;
      font-size: 32px;
      text-align: left;
      font-weight: 600;
      color: ${colors.almostBlack};
      margin: 0 0 35px 0;
      padding: 0;
  }

          .confirmed {
            color: ${colors.green};
          }

          .incomplete {
            color: ${colors.red};
          }

          .checkedIn {
            color: ${colors.hoverBlue};
          }

          .checkedOut {
            color: ${colors.black};
          }

`;
