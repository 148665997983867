import styled from 'styled-components';
import { colors } from 'constants/colors';
import CheckMarkIcon from 'assets/icons/checkMark.svg';

export type CheckboxContainerProps = {
  checked?: boolean;
  error?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  checkboxScale?: number;
  spaceBetween?: string;
}

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  font-size: 16px;
  color: ${colors.black};
  display: flex;
  justify-content: flex-start;

  .label {
    font-size: 16px;
    color: ${colors.black};
    height: ${(props) =>(props.checkboxScale?? 1) * 20}px;
    line-height: ${(props) =>(props.checkboxScale?? 1) * 20}px;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkbox {
    background-color: ${(props) =>(props.backgroundColor?? null)};
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkbox:after {
    display: block;
  }
  /* Style the checkmark/indicator */
  .checkbox::after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    background: url(${CheckMarkIcon}) no-repeat center center;
  }

  /* Hide the browser's default checkbox */
  .input {
    display: none;
  }

  /* Create a custom checkbox */
  .checkbox {
    position: relative;
    top: 4px;
    left: 0;
    margin-right: ${(props) =>(props.spaceBetween?? '10px')};
    height: 20px;
    width: 20px;
    background-color: ${(props) =>(props.backgroundColor?? colors.white)};
    border: 1px solid ${(props) =>(props.error? colors.red: props.borderColor?? colors.signInBorderColor)};
    transform: scale(${(props) =>(props.checkboxScale?? 1)});
    cursor: pointer;

    /* Create the checkmark/indicator (hidden when not checked) */
    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }
`;