import styled from 'styled-components';
import { colors } from 'constants/colors';

export const AccountHeaderContainer = styled.div`
  width: 100%;

  .accountLink {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;

    a {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: ${colors.black};
    margin-bottom: 8px;
  }

  h2 {
    font-size: 34px;
    font-weight: 600;
    color: ${colors.black};
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: ${colors.black};
  }
`;
