import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RoomOptionsContainer, OptionCardContainer } from './style';
import Card from 'components/Card';
import { useRecoilState } from 'recoil';
import { selectedRooms } from 'states/CheckOutStates/atoms';
import { GreenCheckIcon } from 'assets/icons';
import { useGetAvailableRooms } from 'customHooks/useGetAvailableRooms';

const RoomOptions: React.FC = () => {

  const history = useHistory();
  
  const [persistSelectedRooms, setPersistSelectedRooms] = useRecoilState(selectedRooms);
  const { searchResult, noAvailableRooms } = useGetAvailableRooms()
  const windowRoomType = searchResult.find((roomType) => roomType.name === "Rook" || roomType.name === "Knight")
  const noWindowRoomType = searchResult.find((roomType) => roomType.name.includes("Inside"))

  useEffect(() => {
    if( searchResult.length && !(windowRoomType && noWindowRoomType)) {
      history.push('/addOnOptions');
    }
  }, [searchResult, windowRoomType, noWindowRoomType, history])

  return (
    <RoomOptionsContainer>
      {noAvailableRooms? (
        <>
          <div className="noRoomsTitle">
            The HORROR
          </div>
          <div className='noRoomsPicture'></div>
        </>
      ): (
        <>
          <div className="roomOptionsTitle">
            Room options:
          </div>    
          {noWindowRoomType && windowRoomType &&
            <>
              <Card selected={persistSelectedRooms.roomTypeId===noWindowRoomType.roomTypeId}>
                <OptionCardContainer
                  selected={persistSelectedRooms.roomTypeId===noWindowRoomType.roomTypeId}
                  onClick={()=> {
                    const updatedRooms = {...persistSelectedRooms}
                    updatedRooms.roomTypeId = noWindowRoomType.roomTypeId
                    updatedRooms.pricePerNight = noWindowRoomType.pricePerNight
                    updatedRooms.name = noWindowRoomType.name
                    updatedRooms.bed = noWindowRoomType.bed
                    updatedRooms.hasWindows = noWindowRoomType.hasWindows
                    setPersistSelectedRooms(updatedRooms)
                    localStorage.setItem('selectedRooms', JSON.stringify(updatedRooms))
                  }}
                >
                  <div className="optionCardTitle">
                    A room with a skylight
                  </div>
                  <div className="optionCardAttributes">
                    <div className="roomAttribute">
                      <GreenCheckIcon />
                      Most affordable option
                    </div>
                    <div className="roomAttribute">
                      <GreenCheckIcon />
                      Natural light
                    </div>
                  </div>
                  <div className="windowsOption">
                    <div className="customizedRadio"></div>
                    <div className="optionDescription">
                      Included in current room price
                    </div>
                  </div>
                </OptionCardContainer>
              </Card>
              <Card selected={persistSelectedRooms.roomTypeId===windowRoomType.roomTypeId}>
                <OptionCardContainer
                  selected={persistSelectedRooms.roomTypeId===windowRoomType.roomTypeId}
                  onClick={()=> {
                    const updatedRooms = {...persistSelectedRooms}
                    updatedRooms.roomTypeId = windowRoomType.roomTypeId
                    updatedRooms.pricePerNight = windowRoomType.pricePerNight
                    updatedRooms.name = windowRoomType.name
                    updatedRooms.bed = windowRoomType.bed
                    updatedRooms.hasWindows = windowRoomType.hasWindows
                    setPersistSelectedRooms(updatedRooms)
                    localStorage.setItem('selectedRooms', JSON.stringify(updatedRooms))
                  }}
                >
                  <div className="optionCardTitle">
                    A room with a view
                  </div>
                  <div className="optionCardAttributes">
                    <div className="roomAttribute">
                      <GreenCheckIcon />
                      Floor to ceiling window with view of downtown
                    </div>
                  </div>
                  <div className="windowsOption">
                    <div className="customizedRadio"></div>
                    <div className="optionDescription">
                      {((windowRoomType.pricePerNight - noWindowRoomType.pricePerNight)>0? '+': '') + `$${(windowRoomType.pricePerNight - noWindowRoomType.pricePerNight).toFixed(2)}`}
                    </div>
                    <div className="explanation">
                      per night, not including tax / fees
                    </div>
                  </div>
                </OptionCardContainer>
              </Card>
            </>
          }
        </>
      )}
    </RoomOptionsContainer>
  );
}

export default RoomOptions;
