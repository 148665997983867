import React from 'react';
import { ModalContainer } from './style';

export type ModalProps = {
  open: boolean;
  children: JSX.Element;
  opacity?: string;
  top?: string;
  mobile?: boolean;
};

const Modal: React.FC<ModalProps> = ({ open, children, opacity, top, mobile }) => {
  return (
    <>
      {open?
        <ModalContainer
          opacity={opacity}
          top={top}
          mobile={mobile}
        >
          {children}
        </ModalContainer>
        : null
      }
    </>
  )
}

export default Modal;
