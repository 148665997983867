import styled from 'styled-components';
import { colors } from 'constants/colors';

export const ModalContainer = styled.div<{ opacity?: string, top?: string, mobile?: boolean }>`
  overflow-y: auto;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: ${(props) => (props.top ? props.top : '100px')};
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 200;
  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: ${colors.modalBackground};
    opacity: ${(props) => (props.opacity ? props.opacity : '40%')};
    z-index: 100;
  }

  ${({mobile}) => mobile?`
    @media only screen and (min-width: 767px) {
      display: none;
    }
  `: null};
`;
