import styled from 'styled-components';
import { colors } from 'constants/colors';

export const AccountMenuBarContainer = styled.div`
  width: 260px;
  min-width: 260px;
  -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  border: 1px solid ${colors.accounMenuBorderColor};

  .accountMenuItem {
    width: 100%;
    display: flex;
    height: 60px;
    padding: 17px 12px 16px 24px;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    border: 1px solid ${colors.white};

    .accountMenuIcon {
      margin-right: 16px;
    }

    span {
      font-size: 18px;
      height: 27px;
      line-height: 27px;
      font-weight: 400;
      color: ${colors.black};
      text-decoration: none;
    }
  }
  .accountSubMenu {
    .accountMenuItem {
      padding: 17px 12px 16px 64px;
    }
  }


  .active {
    background-color: ${colors.lightBlueTransp};
    border: 1px solid ${colors.hoverBlue};
  }
`;
