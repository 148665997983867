import React, { FC } from 'react';
import { ProfileContainer } from './style';
import Card from 'components/Card';

const Profile: FC = () => {

  return (
    <ProfileContainer>
      <Card
        padding='32px'
      >
        Profile
      </Card>
    </ProfileContainer>
  );
};

export default Profile;
