import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { AccountMenuBarContainer } from './style';
import {
  DashboardIcon,
  PersonalDetailsIcon,
  PersonalEmailIcon,
  PersonalPhoneIcon,
  BookingsIcon,
  BillingIcon,
  SecurityIcon,
  HelpIcon
} from 'assets/icons';
import { Icon } from 'components/Icon';

const AccountMenuBar: FC = () => {

  return (
    <AccountMenuBarContainer>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/dashboard"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon" width={24} height={24}>
          <DashboardIcon />
        </Icon>
        <span>Dashboard</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/profile"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon" width={24} height={23}>
          <PersonalDetailsIcon />
        </Icon>

        <span>Profile</span>
      </NavLink>

      <div className="accountSubMenu">
        <NavLink
          className={"accountMenuItem"}
          to={"/account/personalDetails"}
          activeClassName="active"
        >
          <Icon className="accountMenuIcon" width={24} height={23}>
            <PersonalDetailsIcon />
          </Icon>

          <span>Personal Details</span>
        </NavLink>
        <NavLink
          className={"accountMenuItem"}
          to={"/account/email"}
          activeClassName="active"
        >
          <Icon className="accountMenuIcon" width={24} height={16}>
            <PersonalEmailIcon />
          </Icon>
          <span>Email</span>
        </NavLink>
        <NavLink
          className={"accountMenuItem"}
          to={"/account/phoneNumber"}
          activeClassName="active"
        >
          <Icon className="accountMenuIcon" width={24} height={28}>
            <PersonalPhoneIcon />
          </Icon>
          <span>Phone Number</span>
        </NavLink>
      </div>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/bookings"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon" width={24} height={20}>
          <BookingsIcon />
        </Icon>
        <span>Bookings</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/billing"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon" width={24} height={17}>
          <BillingIcon />
        </Icon>
        <span>Billing</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/security"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon" width={24} height={30}>
          <SecurityIcon />
        </Icon>
        <span>Security</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem"}
        to={"/help"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon" width={24} height={24}>
          <HelpIcon />
        </Icon>
        <span>Help</span>
      </NavLink>
    </AccountMenuBarContainer>
  );
};

export default AccountMenuBar;
