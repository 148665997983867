import styled from "styled-components";
import { colors } from "constants/colors";
import { BasicSectionContainer } from "../../../style";

export const AccountContentContainer = styled(BasicSectionContainer)`
  .accountBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: stretch;
    gap: 32px;

    .cardContent {
      min-width: 260px;
      -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      border: 1px solid ${colors.accounBorderColor};
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-content: stretch;
      flex: 1;
      color: ${colors.commonBlack};
      .secHeader {
        font-size: 24px;
        font-weight: 600;
        color: ${colors.almostBlack};
        padding: 0;
        margin: 3px 0 17px 0;
      }
      .description {
        font-size: 16px;
        line-height: 24px;
        color: ${colors.almostBlack};
      }

      .buttonWithTooltip {
        position: relative;
        overflow: visible;
        align-self: flex-start;
        .tool {
          position: absolute;
          top: -87px;
          left: 112px;
        }
      }
      .secInput {
        label {
          color: ${colors.secLabel};
        }
        .highLight {
        }
      }
      .passwordContainer {
        //position: relative;
        display: flex;
        flex-direction: column;
        width: 500px;
        margin: 32px 0 0 0;
        .bar {
          color: ${colors.borderGrey3};
          div {
            background-color: ${colors.borderGrey3};
          }
        }

        .passwordStrengthBar {
          width: 340px;
          margin-bottom: 0;
        }
        .passResetPhrase {
          font-size: 16px;
          font-weight: 400;
          color: ${colors.almostBlack};
          line-height: 24px;
          margin-bottom: 28px;
          a {
            color: ${colors.helpBoxBorderHover};

          }
        }
        .passwordStrengthBarText {
          min-height: 12px;
        }

        .inpuntCont {
          width: 340px;
          position: relative;
          label {
            margin-bottom: 10px;
          }
          .secInput  {
            margin-bottom: 14px;
          }
          .warning,
          .success {
            height: auto;
            min-height: 20px;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            margin-top: 7px;
          }

        }
        .passwordStrengthBar {
            margin-top: -7px;
          }

        .oldPasswordLock,
        .oldPasswordUnlock,
        .newPasswordLock,
        .newPasswordUnlock {
          position: absolute;
          top: 47px;
          right: 12px;
          cursor: pointer;
        }
      }

      .buttonContainer {
        position: relative;

        svg {
          position: absolute;
          top: -85px;
          left: 150px;
        }
      }
    }
  }
`;
