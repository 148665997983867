import styled from 'styled-components';
import { colors } from 'constants/colors';

export type InputFieldContainerProps = {
  labelPosition?: 'left' | 'center';
  labelColor?: string;
  labelSize?: string;
  labelWeight?: string;
  borderColor?: string;
  inputHeight?: string;
  inputTextColor?: string;
  inputFontSize?: string;
  inputFontWeight?: string;
  inputBackgroundColor?: string;
  error?: boolean;
  success?: boolean;
  width?: string;
  marginBottom?: string;
  iconUrl?: string;
};

export const FormInputFieldContainer = styled.div<InputFieldContainerProps>`
  position: relative;
  width: ${(props) => (props.width?? '100%')};
  margin-bottom: ${(props) => props.marginBottom?? '20px'};
  color: ${colors.white};

  .icon {
    position: absolute;
    left: 200px;
    top: 45px;
    width: 20px;
    height: 20px;
    z-index: 100;
  }

  input {
    font-size: ${(props) => (props.inputFontSize?? '16px')};
    font-weight: ${(props) => (props.inputFontWeight?? '400')};
    font-family: 'Titillium Web', sans-serif;
    width: 100%;
    box-sizing: border-box;
    height: ${(props) => (props.inputHeight?? '40px')};
    border: 1px solid
      ${(props) => props.error? colors.red: (props.borderColor?? colors.signInBorderColor)};
    color: ${(props) => props.inputTextColor?? colors.black};
    padding-left: 10px;
    background-color: ${(props) => props.inputBackgroundColor?? colors.white};

    :focus {
      border-color: ${(props) => props.error? colors.red: colors.hoverBlue};
    }
  }



  label {
    width: 100%;
    display: block;
    font-size: ${(props) => (props.labelSize?? '14px')};
    font-weight: ${(props) => (props.labelWeight?? '600')};
    color: ${(props) => props.error? colors.red: (props.labelColor?? colors.labelGrey)};
    margin-bottom: 5px;
    letter-spacing: 0.75px;
    text-align: ${(props) => props.labelPosition === 'center'? 'center': 'left'};

    .required {
      font-size: 18px;
      color: ${colors.red};
    }

    .highLight {
      font-weight: bold;
    }

  }

  .warning {
    width: 100%;
    height: 20px;
    line-height: 20px;
    position: absolute;
    color: ${colors.red};
    font-size: 16px;
    font-weight: 600;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    text-align: left;
    margin-top: 5px;
  }

  .success {
    width: 100%;
    height: 20px;
    line-height: 20px;
    position: absolute;
    color: ${colors.questionLinkText};
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
  }

  @media only screen and (max-width: 767px) {
    .warning {
      white-space: normal;
      overflow: visible;
      text-overflow: unset;
    }
  }
`;
